import {defineStore} from "pinia";
import DateTime from "moment";
import axios from 'axios';
import moment from "moment-timezone";
import {BsAlert} from "../mixins/swal_mixins";

export const useTournamentStore = defineStore('tournament', {
  state: () => {
    return {
      single: true,
      league: false,
      postal: false,
      name: null,
      description: null,
      address: null,
      city: null,
      state: null,
      zipCode: null,
      startDate: moment(DateTime.now()).startOf('day').toDate(),
      startTime: moment(DateTime.now()).add(1, 'hour').startOf('hour').toDate(),
      endDate: null,
      multiDay: false,
      times: [{'id': 1, 'time': moment(DateTime.now()).add(1, 'hour').startOf('hour').toDate()}],
      sport: null,
      fullTargetList: null,
      targets: [],
      style: 'paper',
      bales: 25,
      bale_competitors: 4,
      auto_assign: true,
      self_assign: false,
      ends: 10,
      arrows: 3,
      accept_button: false,
      products: [{product: null, price: null}],
      clubs: [],
      club_id: null,
      unsavedChanges: false,
      extraPrices: [],
      price: null,
      showRegForm: false,
      phone: false,
      number: false,
      gender: true,
      regCity: false,
      regState: false,
      country: false,
      registrationExtras: [],
      extrasNext: [],
      teamClubName: false,
      teamClubType: false,
      waiver: null,
      banner: null,
      timezone: 'America/Denver',
      rounds: [{
        name: 'Round 1',
        line_times: [],
        target: null,
        ends_count: null,
        arrows_count: null,
        bale_count: null,
        bale_competitors: null,
        auto: null,
        self_assign: null,
        hidden: null,
        accept: null,
      }],
      showRounds: false,
      activeRound: 0,
      tournamentSlug: null,
      roundTimes: [],
      tempTime: null,
      useCustomPricing: false,
      pricingExtraSelected: null,
      customPricing: [],
      saveLoading: false,
      sportDefaults: {},
      tournamentStatus: 'draft',
      defaultTournament: null,
      defaultClub: null,
      defaultUser: null,
      defaultDefaults: null,
      allowOffline: true,
      hasStripe: false,
      hide_targets: true,
    };
  },
  getters: {
    registrationTotal(state) {
      let price = parseInt(state.price ?? 0);
      return price + state.bowscoreFee;
    },
    bowscoreFee(state) {
      return state.league ? 5 : 2;
    },
    isComplete(state) {
      return state.targetsComplete && (state.price != null);
    },
    canSave(state) {
      return state.unsavedChanges && state.name != null && state.sport != null;
    },
    sportTargets(state) {
      let returnArray = [];
      let targets = state.fullTargetList[state.sport ? state.sport : 'all'];
      let clubTargets = state.fullTargetList['club'];
      targets = {...targets, ...clubTargets};
      Object.keys(targets).forEach(function (id) {
        let target = {};
        target['id'] = id;
        target['name'] = targets[id];
        returnArray.push(target);
      });
      return returnArray;
    },
    typeSelected(state) {
      return state.single || state.league || state.postal;
    },
    nameComplete(state) {
      if (!state.typeSelected) return false;
      return state.name != null && state.name.length;
    },
    addressComplete(state) {
      if (!state.nameComplete) return false;
      if (state.postal) return true;
      return state.address != null && state.city != null && state.state != null && state.zipCode != null;
    },
    timeComplete(state) {
      if (!state.addressComplete) return false;
      return state.startDate != null && state.startTime != null;
    },
    sportComplete(state) {
      if (!state.timeComplete) return false;
      return state.sport != null;
    },
    targetsComplete(state) {
      if (!state.sportComplete) return false;
      return state.targets.length;
    },

    activeLink() {
      let path = window.location.pathname.split('/');
      if (path.length > 1) {
        return path[1] ? path[1] : 'home';
      }
      return 'home'
    },
    timezoneOptions() {
      let list = [
        'Africa/Cairo',
        'America/Adak',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
        'America/New_York',
        'America/Noronha',
        'America/Santiago',
        'America/Sao_Paulo',
        'Asia/Dhaka',
        'Asia/Novosibirsk',
        'Asia/Tashkent',
        'Asia/Tokyo',
        'Asia/Vladivostok',
        'Atlantic/Cape_Verde',
        'Australia/Hobart',
        'Australia/Perth',
        'Europe/Brussels',
        'Europe/Minsk',
        'Europe/Moscow',
        'Pacific/Gambier',
        'Pacific/Midway',
        'UTC',
      ]
      return list;
    }
  },
  actions: {
    toggleHideTargets() {
      this.hide_targets = !this.hide_targets;
    },
    discard() {
      if (this.tournamentSlug != null) {
        this.customPricing = [];
        this.setDefaultValues(this.defaultTournament, this.defaultClub, this.defaultUser, this.defaultDefaults);
      } else {
        this.resetAll();
      }
      this.unsavedChanges = false;
    },
    resetAll() {
      let defaults = this.sportDefaults;
      let targets = this.fullTargetList;
      this.$reset();
      this.banner = 'formEdit';
      this.sportDefaults = defaults;
      this.fullTargetList = targets;

    },
    touched() {
      this.unsavedChanges = true;
    },
    updateCustomPrice(option, multiple, stringValue) {
      let value = stringValue.length ? parseInt(stringValue) : null;
      let index = this.customPricing.findIndex((f) => f.option === option.name && f.multiple_id === multiple.id);
      if (index >= 0) {
        this.customPricing[index]['value'] = value;
      } else {
        this.customPricing.push({option: option.name, multiple_id: multiple.id, value: value});
      }
    },
    roundTargetClicked(target, round) {
      let current = this.rounds[round]['target'];
      if (current === target) {
        this.rounds[round]['target'] = null;
      } else {
        this.rounds[round]['target'] = target;
      }
      this.touched();
    },
    setRoundTime(value) {
      let dateTime = new Date(value);
      let momentTime = moment(dateTime);
      this.tempTime.time = momentTime.format('DD MMM yyyy hh:mm A');
      this.times.push(this.tempTime);
      this.roundTimes[this.activeRound].push(this.tempTime.id);
      this.tempTime = null;
      this.touched();
    },

    setRoundTimesToRound() {
      for (let index in this.roundTimes) {
        let times = this.times.filter((f) => {
          return this.roundTimes[index].includes(f.id);
        });
        this.rounds[index].line_times = times;
      }
    },
    async save() {
      this.setRoundTimesToRound();
      let data = this.$state;
      this.saveLoading = true;
      data['isComplete'] = this.isComplete;
      let scroll = false;
      await axios.post(`/create_new_tournament`, data).then(async ({data}) => {
        this.saveLoading = false;
        if (data.success) {
          this.unsavedChanges = false;
          this.tournamentSlug = data.slug;
          this.tournamentId = data.id;
          const endDraft = this.isComplete && this.tournamentStatus === 'draft';
          await BsAlert.fire({
            title: "Tournament saved",
            html: endDraft ? 'Tournament setup is complete!' : '',
            icon: 'success',
            reverseButtons: true,
            showCloseButton: true,
            showConfirmButton: endDraft,
            showCancelButton: this.isComplete,
            showDenyButton: endDraft,
            confirmButtonText: 'Set to public',
            cancelButtonText: endDraft ? 'Keep as draft' : 'Ok',
            denyButtonText: 'Set to private'
          }).then((value) => {
            if (value.isConfirmed) {
              axios.post(`/tournaments/${data.slug}/manage`, {'update': {'status': 'public'}}).then((v) => {
                return window.location.href = `/tournaments/${data.slug}`;
              });
            } else if (value.isDismissed && value.dismiss === 'close') {
              if (this.showRegForm || this.showRounds) scroll = true;
              this.showRegForm = false;
              this.showRounds = false;
            } else if (value.isDismissed && value.dismiss === 'cancel') {
              return window.location.href = `/tournaments/${data.slug}`;
            } else {
              axios.post(`/tournaments/${data.slug}/manage`, {'update': {'status': 'private'}}).then((v) => {
                return window.location.href = `/tournaments/${data.slug}`;
              });
            }
          });
        } else {
          await BsAlert.fire({title: "Failed to save", html: data.error, icon: 'error', showCancelButton: false});
        }
      });
      return scroll;
    },
    setAddress(address) {
      this.address = `${address.address1}${address.address2 == null ? '' : ` ${address.address2}`}`;
      this.city = address.city;
      this.state = address.state;
      this.zipCode = address.zip;
    },
    setDefaultSuppliers(tournament, club, user, defaults) {
      this.defaultTournament = tournament;
      this.defaultClub = club;
      this.defaultUser = user;
      this.defaultDefaults = defaults;
    },
    setDefaultValues(tournament, club, user, defaults) {
      this.setDefaultSuppliers(tournament, club, user, defaults);
      this.club_id = club.id;
      this.sportDefaults = defaults;
      if (club.stripe != null) this.hasStripe = true;
      if (!tournament.hasOwnProperty('id')) {
        if (club.hasOwnProperty('range_address')) this.setAddress(club.range_address);
        return false;
      }
      this.tournamentSlug = tournament.slug;
      this.name = tournament.name;
      this.description = tournament.description;
      if (tournament.is_league) {
        this.setTournamentType('league');
      } else if (tournament.is_master_postal) {
        this.setTournamentType('postal');
      }
      if (tournament.hasOwnProperty('address')) this.setAddress(tournament.address);
      this.startTime = tournament.start_time;
      this.timezone = tournament.timezone;
      this.tournamentStatus = tournament.status;
      let sDate = new Date(tournament.start_time);
      let eDate = new Date(tournament.end_time);
      let start = moment(sDate);
      let end = moment(eDate);
      this.startDate = tournament.start_time;
      this.endDate = tournament.end_time;
      this.multiDay = start.dayOfYear() !== end.dayOfYear();
      this.times = tournament.line_times;
      this.sport = `${tournament.sport.id}`;
      this.targets = tournament.targets;
      this.style = tournament.target_style === 'Bales' ? 'paper' : '3d';
      let location = tournament.locations[0];
      this.bales = location.bales;
      this.bale_competitors = tournament.bale_competitors;
      this.auto_assign = tournament.auto_assignment;
      this.self_assign = tournament.self_assignment;
      let firstRound = tournament.rounds[0];
      this.ends = firstRound.ends_count;
      this.arrows = firstRound.arrows_count;
      this.accept_button = tournament.accept_button;
      this.hide_targets = tournament.hide_targets;
      let roundList = [];
      for (let round of tournament.rounds) {
        let roundLocation = round.locations[0];
        let data = {
          id: round.id,
          name: round.name,
          line_times: round.line_times,
          target: round.target,
          ends_count: round.ends_count,
          arrows_count: round.arrows_count,
          bale_count: roundLocation.bales,
          bale_competitors: roundLocation.baleCompetitors ?? (location.bale_competitors ?? tournament.bale_competitors),
          auto: null,
          self_assign: null,
          hidden: round.hidden,
          accept: tournament.accept_button,
        };
        this.roundTimes.push(round.line_times.map((m) => m.id));
        roundList.push(data);
      }
      this.rounds = roundList;
      this.setFormData(tournament);
      this.setPaymentData(tournament);
      return true;
    },
    setPaymentData(tournament) {
      if (tournament.payments === null) return;
      this.price = tournament.payments.price;
      this.allowOffline = tournament.payments.allow_offline_payments;
      this.extraPrices = (tournament.payments.pricing_extras ?? []).filter((f) => f['purchase'] !== true);
      let products = (tournament.payments.pricing_extras ?? []).filter((f) => f['purchase'] === true);
      let productList = [];
      for (let product of products) {
        productList.push({'product': product.name, 'price': product.amount, 'imageUrl': product.imageUrl});
      }
      if (productList.length) this.products = productList;
      for (let restriction of tournament.restrictions) {
        if (restriction.type === 'price') {
          this.useCustomPricing = true;
          let value = tournament.payments.pricing_extras[restriction.type_id];
          let amount = parseInt(this.price);
          if (value.type === '+') {
            amount = parseInt(value.amount) + amount;
          } else {
            amount = amount - parseInt(value.amount);
          }
          let formItem = tournament.regform.find((f) => f.id === restriction.regform_id);
          this.pricingExtraSelected = formItem.name;
          let object = {option: formItem.name, multiple_id: restriction.multiple_id, value: amount};
          this.customPricing.push(object);
        }
      }
    },
    setFormData(tournament) {
      this.registrationExtras = [];
      for (let data of tournament.regform) {
        if ((['first_name', 'last_name', 'name', 'email']).includes(data.option)) continue;
        if (!data.enabled) continue;
        if (data.option === 'gender') {
          this.gender = true;
        } else if (data.option === 'phone') {
          this.phone = true;
        } else if (data.option === 'city') {
          this.regCity = true;
        } else if (data.option === 'state') {
          this.regState = true;
        } else if (data.option === 'country') {
          this.country = true;
        } else if (data.option === 'membership-number' || data.option === 'membership_number') {
          this.number = true;
        } else if (data.option === 'team/club' || data.option === 'team_club') {
          this.teamClubType = true;
        } else if (data.option === 'team/club-name' || data.option === 'team_club_name') {
          this.teamClubName = true;
        } else {
          this.registrationExtras.push(data);
        }
      }
    },
    setBanner(value) {
      this.banner = value;
    },
    navigateRounds() {
      let count = 1;
      let timeList = [];
      for (let i in this.rounds) {
        let round = this.rounds[i];
        let times = JSON.parse(JSON.stringify(this.times))
        if (round.name === null) this.rounds[i].name = `Round ${count}`;
        if (round.line_times.length === 0) this.rounds[i].line_times = times;
        if (round.ends_count === null) this.rounds[i].ends_count = this.ends;
        if (round.arrows_count === null) this.rounds[i].arrows_count = this.arrows;
        if (round.bale_count === null) this.rounds[i].bale_count = this.bales;
        if (round.bale_competitors === null) this.rounds[i].bale_competitors = this.bale_competitors;
        if (round.auto === null) this.rounds[i].auto = this.auto_assign;
        if (round.self_assign === null) this.rounds[i].self_assign = this.self_assign;
        if (round.accept === null) this.rounds[i].accept = this.accept_button;
        timeList.push(this.rounds[i].line_times.map((f) => f.id));
        count++;
      }
      this.showRounds = true;
      this.roundTimes = timeList;
      window.scroll({top: 0, behavior: "smooth"});
    },
    removeRoundTime(round, index) {
      this.rounds[round]['line_times'].splice(index, 1);
    },
    getNewTime() {
      let previousTime = this.times.length ? this.times.at(-1) : this.startTime;
      let time = new Date(this.times.length ? previousTime.time : previousTime);
      let momentTime = moment(time);
      let adjusted = momentTime.add(1, 'hours');
      let finalTime = adjusted.format('DD MMM yyyy hh:mm A');
      return finalTime;
    },
    addRoundTime(id) {
      this.touched();
      if (id == null) {
        let id = this.getTimeId(this.times);
        let finalTime = this.getNewTime();
        this.tempTime = {'id': id, 'time': finalTime};
      } else {
        let time = this.times.find((f) => f.id === parseInt(id));
        this.rounds[this.activeRound]['line_times'].push(time);
        this.roundTimes[this.activeRound].push(time.id);

      }
      this.sortRoundTimes(this.activeRound);
    },
    sortRoundTimes(round) {
      this.rounds[round]['line_times'] = this.sortTimes(this.rounds[round]['line_times'])
    },
    editRoundCount(int) {
      this.touched();
      let currentCount = this.rounds.length;
      if (int > currentCount) {
        for (let i = 0; i < int - currentCount; i++) {
          let times = JSON.parse(JSON.stringify(this.times))
          this.rounds.push({
            id: null,
            name: `Round ${currentCount + i + 1}`,
            line_times: times,
            target: null,
            ends_count: this.ends,
            arrows_count: this.arrows,
            bale_count: this.bales,
            bale_competitors: this.bale_competitors,
            auto: this.auto_assign,
            self_assign: this.self_assign,
            hidden: false,
            accept: this.accept_button,
          })
          this.roundTimes.push(times.map((m) => m.id));
        }
      } else {
        this.rounds.length = int;
        this.roundTimes.length = int;
      }
    },
    removeSubOption(index, subIndex) {
      this.registrationExtras[index].multiple.splice(subIndex, 1);
      this.touched();
    },
    getNextId(index, count) {
      let id = count + 1;
      let exists = this.registrationExtras[index].multiple.find((f) => f.id === id);
      while (exists) {
        id++;
        exists = this.registrationExtras[index].multiple.find((f) => f.id === id);
      }
      return id;
    },
    addNextToExtras(index, count) {
      if (this.extrasNext[index] != null) {
        let id = this.getNextId(index, count);
        this.registrationExtras[index].multiple.push({'id': id, 'label': this.extrasNext[index]});
        this.extrasNext[index] = null;
      }
    },
    removeOption(index) {
      this.registrationExtras.splice(index, 1);
      this.touched();
    },
    addOption(order) {
      this.registrationExtras.push({
        name: 'Option',
        multiple: [{'id': 1, 'label': 'option 1'}, {'id': 2, 'label': 'option 2'}, {'id': 3, 'label': 'option 3'}],
        type: 'radio',
        description: '',
        order: order
      });
      this.touched();
    },
    toggleMultiDay() {
      if (this.multiDay) {
        this.endDate = null;
      } else {
        let start = new Date(this.startDate)
        let adjusted = moment(start).add(1, 'days');
        this.endDate = adjusted.endOf('day').toDate();
      }
      this.multiDay = !this.multiDay;
    },
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    addProduct() {
      this.products.push({product: null, price: null});
    },
    addTarget(target) {
      const index = this.targets.findIndex((f) => f.id === target.id);
      if (index > -1) {
        this.targets.splice(index, 1);
      } else {
        this.targets.push(target);
      }
    },
    setFullTargetList(targetList) {
      this.fullTargetList = targetList;
    },
    setSport(sportId) {
      this.sport = sportId;
      if (this.tournamentSlug != null) return;
      let extras = [];
      for (let sportDefault of Object.values(this.sportDefaults[sportId])) {
        switch (sportDefault.name) {
          case 'Gender':
            this.gender = true;
            break;
          case 'Membership Number':
            this.number = true;
            break;
          case 'Team/Club':
            this.teamClubType = true;
            break;
          case 'Team/Club Name':
            this.teamClubName = true;
            break;
          case 'Address':
            this.address = true;
            break;
          case 'City':
            this.regCity = true;
            break;
          case 'Country':
            this.country = true;
            break;
          case 'State':
            this.regState = true;
            break;
          case 'Phone Number':
            this.phone = true;
            break;
          case 'Zip Code':
          case 'Birthdate':
            break;
          default:
            extras.push(sportDefault);
        }
      }
      this.registrationExtras = extras;
    },
    formattedTime(time) {
      let dateTime = new Date(time);
      let momentTime = moment(dateTime);
      return momentTime.format('DD MMM yyyy hh:mm A');
    },
    addTime() {
      let id = this.getTimeId(this.times);
      let finalTime = this.getNewTime();
      this.times.push({'id': id, 'time': finalTime});
      this.times = this.sortTimes(this.times);
      this.touched();
    },
    sortTimes(list) {
      list.sort((a, b) => {
        let aTime = new Date(a.time);
        let bTime = new Date(b.time);
        if (aTime > bTime) return 1;
        if (aTime < bTime) return -1;
        return 0;
      })
      return list;
    },
    otherTimes(round) {
      let roundIds = this.roundTimes[round] ?? [];
      let list = this.times.filter((t) => !roundIds.includes(t.id));
      return list;
    },
    getTimeId(list) {
      let id = 1;
      for (let time of list) {
        if (time.id > id) id = time.id;
      }
      return id + 1;
    },
    removeTime(index) {
      this.touched();
      let id = this.times[index].id;
      this.times.splice(index, 1);
      for (let index in this.roundTimes) {
        let filtered = this.roundTimes[index].filter((f) => f !== id);
        this.roundTimes[index] = filtered;
      }
      for (let round of this.rounds) {
        let found = round.line_times.findIndex((i) => {
          return i.id === id
        });
        if (found !== -1) {
          round.line_times.splice(found, 1);
        }
      }
    },
    setTournamentType(type) {
      if (type === 'league') {
        this.league = true;
        this.single = false;
        this.postal = false;
      } else if (type === 'single') {
        this.single = true;
        this.postal = false;
        this.league = false;
      } else {
        this.postal = true;
        this.league = false;
        this.single = false;
      }
    }
  },
})